<template>
        <el-dialog title="Mesaje Contact" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
            <el-col :md='8'>
                <el-form-item prop="Nume" label='Nume' >
                    <el-input  class='full-width' v-model='selectedObject.Nume' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Email' >
                    <el-input prop="Email"  class='full-width' v-model='selectedObject.Email' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="Titlu" label='Titlu' >
                    <el-input  class='full-width' v-model='selectedObject.Titlu' />
                </el-form-item>
            </el-col> 
            <el-col :md='24'>
                <el-form-item prop="Comentariu" label='Comentariu' >
                    <vue2-tinymce-editor v-model="selectedObject.Comentariu"></vue2-tinymce-editor>
                </el-form-item>
            </el-col>
        </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import { Vue2TinymceEditor } from "vue2-tinymce-editor";       

    export default {
        name: "Mesaje_contact_dialog",
        extends: BasePage,
        components: {
            Vue2TinymceEditor
        },
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObject: {
                    Nume: '' , Email: '' , Titlu: '' ,                 },
                Info:{
                                    },
                rules: {
                    //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
                    Nume: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Email: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Titlu: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                    this.selectedObject = {};
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("mesaje_contact/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("mesaje_contact/get_info_for_dialog" );
                            },
                        save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("mesaje_contact/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>